<style scoped>
table tr td {
  padding: 4px;
  font-size: 12px;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0;
}

.error-integradora {
  border-top: 2px solid transparent;
}

.error-integradora .error {
  font-size: 8px;
  padding: 0px 10px 10px 10px;
}
</style>

<template>
  <modal name="info-pedido" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <div class="modalbox" v-if="!!pedido.id">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Detalhes do Pedido">
            <h2>Cabeçalho</h2>
            <div class="table-responsive">
              <table class="table table-hover">
                <tr>
                  <td colspan="3" class="error">
                    {{ !!pedido.critica_integradora ? pedido.critica_integradora : 'Pedido rejeitado, verifique os itens' }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <small>Núm. Ped. Cli.</small>
                    <p>{{ pedido.orcamento }}</p>
                  </td>
                  <td>
                    <small>Grupo</small>
                    <p>{{ pedido.grupo }}</p>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <small>Cliente</small>
                    <p>{{ pedido.cliente.id }} - {{ pedido.cliente.razSoc }}</p>
                  </td>
                  <td>
                    <small>Emitente</small>
                    <p>{{ pedido.emitente.desc }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>Dt.</small>
                    <p>{{ pedido.dtHorInc | date }}</p>
                  </td>
                  <td>
                    <small>Cobrança</small>
                    <p>{{ pedido.cobranca.id }} - {{ pedido.cobranca.desc }}</p>
                  </td>
                  <td>
                    <small>Plano Pagamento</small>
                    <p>{{ pedido.plano.id }} - {{ pedido.plano.desc }}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <small>Itens</small>
                    <p>{{ pedido.qtdeIt }}</p>
                  </td>
                  <td>
                    <small>Total</small>
                    <p>{{ pedido.vlTot | monetary }}</p>
                  </td>
                  <td>
                    <small>Filial</small>
                    <p>{{ pedido.filial.id }} - {{ pedido.filial.desc }}</p>
                  </td>
                </tr>
              </table>
            </div>

            <h2>Itens</h2>
            <div class="table-responsive">
              <table class="table table-hover">
                <thead>
                <tr>
                  <th>Cód.</th>
                  <th>Produto</th>
                  <th>Qt</th>
                  <th>Vl.Unit</th>
                  <th>Vl.Tot</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="item in pedido.itens" >
                  <tr :class="!!item.critica_integradora ? 'error' : ''">
                    <td>{{ item.idProd }}</td>
                    <td>{{ item.descNF }}</td>
                    <td>{{ item.qtde }}</td>
                    <td>{{ item.vlUnit | monetary }}</td>
                    <td>{{ item.vlTot  | monetary }}</td>
                  </tr>
                  <tr v-if="!!item.critica_integradora" :class="!!item.critica_integradora ? 'error error-integradora' : ''">
                    <td colspan="5" class="error">
                      {{ item.critica_integradora }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button class="button button-primary" v-on:click="reintegrar">Reintegrar Pedido</button>
      <button class="button button-error" v-on:click="cancelarPedido">Cancelar Pedido</button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../components/CloseModal";

export default {
  name: "info-pedido",
  props: ["close", "reintegrar", "cancelar", "pedido"],
  components: { CloseModal },
  methods: {
    cancelarPedido() {
      this.$swal({
        title: "Confirmar cancelamento?",
        text: "Para prosseguir, informe o motivo do cancelamento",
        input: "text",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Sim, cancelar pedido!",
        cancelButtonText: "Fechar",
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.cancelar(res.value);
          }
        })
        .catch(() => {});
    },
  },
};
</script>
