<template>
    <div class="content">
        <div class="content-wrapper">
            <loading :isActive="loading" />
            <h1 class="title">
                <fa-icon v-if="icon" :icon="icon"/>
                {{ descPlural }}
            </h1>
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { loadDescs } from '../helpers/utils';

export default {
  name: 'template-modulo',
  props: ['modulo', 'icon', 'loading'],

  data() {
    return {
      descSingular: '',
      descPlural: '',
    };
  },

  beforeMount() {
    const { descSingular, descPlural } = loadDescs(this.modulo);
    this.descSingular = descSingular;
    this.descPlural = descPlural;
  },
};
</script>
