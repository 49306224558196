<template>
  <div>
    <info-pedido
      :pedido="currentPedido"
      :close="closeInfo"
      :reintegrar="reintegrarPedido"
      :cancelar="cancelarPedido"
    />
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab title="Pendentes de Integração">
        <div class="xrow" style="margin-bottom: 10px">
          <div class="col-1-4">
            <label>De</label>
            <datetime
              input-class="form-control"
              v-model="dataInicio"
              type="date"
              :max-datetime="fim"
              placeholder="Data inicial"
              :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
              value-zone="local"
              format="dd/MM/yyyy"
            />
          </div>
          <div class="col-1-4">
            <label>Até</label>
            <datetime
              input-class="form-control"
              v-model="dataFim"
              type="date"
              :min-datetime="inicio"
              placeholder="Data final"
              :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
              value-zone="local"
              format="dd/MM/yyyy"
            />
          </div>
          <div class="col-1-4">
            <label>&nbsp;</label><br>
            <button class="button button-primary" v-on:click="loadPedidos">
              Buscar
            </button>
          </div>
        </div>
        <lista-pedidos
          :pedidos="pedidos"
          :openInfo="openInfo"
        />
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import TemplateModulo from '../../components/TemplateModulo';
import ListaPedidos from './ListaPedidos';
import { get, post, del } from '../../helpers/apiRequest';
import moment from 'moment';
import InfoPedido from './InfoPedido';

export default {
  name: 'pedido-pendente-integracao',
  components: {
    InfoPedido,
    TemplateModulo,
    ListaPedidos,
  },
  data() {
    return {
      currentPedido: { motivo: '' },
      pedidos: [],
      loading: true,
      dataInicio: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
    };
  },

  computed: {
    inicio() {
      return moment(this.dataInicio).format('YYYY-MM-DD');
    },

    fim() {
      return moment(this.dataFim).format('YYYY-MM-DD');
    },
  },

  methods: {
    loadPedidos() {
      if (this.dataInicio && this.dataFim) {
        this.loading = true;
        get(
          `/admin/pedidos/pendente-integracao?data_inicio=${
            this.inicio
          }&data_fim=${this.fim}`
        )
          .then((pedidos) => {
            this.loading = false;
            this.pedidos = pedidos;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    reintegrarPedido() {
      const { orcamento: id } = this.currentPedido;
      this.loading = true;
      const self = this;
      post(`/admin/pedidos/reintegrar/${id}`)
        .then((response) => {
          this.closeInfo();
          this.showSuccess(response.message, function() {
            self.loadPedidos();
          });
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error.message);
        });
    },
    cancelarPedido(motivo = null) {
      const { orcamento: id } = this.currentPedido;
      this.loading = true;
      const self = this;
      del(`/admin/pedidos/${id}`, { motivo })
        .then((response) => {
          this.closeInfo();
          this.showSuccess(response.message, function() {
            self.loadPedidos();
          });
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error.message);
        });
    },
    openInfo(pedido) {
      this.loading = true;
      get(`/admin/pedidos/${pedido.orcamento}`)
        .then((pedidoDetalhado) => {
          this.currentPedido = pedidoDetalhado;
          this.$modal.show('info-pedido');
        })
        .finally(() => (this.loading = false));
    },
    closeInfo() {
      this.loading = false;
      this.error = false;
      this.$modal.hide('info-pedido');
    },
    showSuccess(message, callback) {
      this.$swal({
        title: 'Sucesso!',
        text: message,
        icon: 'success',
        confirmButtonText: 'Fechar',
      }).then(() => {
        callback ? callback() : null;
      });
    },
    showError(message, callback) {
      this.$swal({
        title: 'Ops, algo deu errado!',
        text: message,
        icon: 'warning',
        confirmButtonText: 'Fechar',
      }).then(() => {
        callback ? callback() : null;
      });
    },
  },

  beforeMount() {
    this.loadPedidos();
  },
};
</script>
