<style scoped>
    .text-ellipsis {
        color: #911;
        font-size: 10px;
        max-width: 150px;
        line-height: 8px;
    }
</style>

<template>
    <div class="table-responsive">
        <table class="table table-hover">
            <thead>
            <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th>Data</th>
                <th>Itens</th>
                <th>Total</th>
                <th>Motivo</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
                <tr v-for="pedido in pedidos">
                    <td class="highlight">{{ pedido.id }}</td>
                    <td>
                        {{ pedido.cliente.razSoc }}
                        <small v-if="pedido.representante.id">
                            RCA {{ pedido.representante.id }} / {{ pedido.representante.desc }}
                        </small>
                    </td>
                    <td>{{ convertDataHora(pedido.dtHorInc) }}</td>
                    <td>{{ pedido.qtdeIt }}</td>
                    <td>{{ pedido.vlTot | monetary }}</td>
                    <td>
                        <div class="text-ellipsis">{{ pedido.critica_integradora }}</div>
                    </td>
                    <td class="actions">
                        <button class="button button-info" type="button" v-on:click="openInfo(pedido)">
                            <fa-icon icon="info-circle"/>
                        </button>
                    </td>
                </tr>
                <tr v-if="pedidos.length === 0">
                    <td colspan="7" class="center">
                        Nenhum pedido encontrado no período especificado
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import moment from 'moment';

  export default {
    name: "lista-pedidos",
    props: [
      'pedidos',
      'openInfo'
    ],
    methods: {
    convertDataHora(dataHora) {
        return moment(String(dataHora)).format('DD/MM/YYYY HH:mm')
    }
    }
  }
</script>